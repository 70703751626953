/* Concact Button*/

.contact__box {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .contact__button a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 10px;
    letter-spacing: 4px;
  }
  
  .contact__button a:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
      0 0 100px #03e9f4;
  }
  
  .contact__button a span {
    position: absolute;
    display: block;
  }
  
  .contact__button a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    animation: btn-anim1 1s linear infinite;
  }
  
  @keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  
  .contact__button a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    animation: btn-anim2 1s linear infinite;
    animation-delay: 0.25s;
  }
  
  @keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  
  .contact__button a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    animation: btn-anim3 1s linear infinite;
    animation-delay: 0.5s;
  }
  
  @keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  
  .contact__button a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    animation: btn-anim4 1s linear infinite;
    animation-delay: 0.75s;
  }
  
  @keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  
  /* Developer Full Stack */
  .btn-shine {
    color: #02fdfd;
    background: linear-gradient(to right, #01c0c7 0, #fff 10%, #01c0c7 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 1s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    white-space: nowrap;
  }
  @-moz-keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 180px;
    }
    100% {
      background-position: 180px;
    }
  }
  @-webkit-keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 180px;
    }
    100% {
      background-position: 180px;
    }
  }
  @-o-keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 180px;
    }
    100% {
      background-position: 180px;
    }
  }
  @keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 180px;
    }
    100% {
      background-position: 180px;
    }
  }
  
  /* Card rotate right 20deg */
  
  .flip-card {
    perspective: 1000px;
  }
  
  .flip-card-inner {
    transition: transform 0.6s;
    /* transform-style: preserve-3d; */
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(20deg);
  }
  
  /* Card rotate left */
  
  .flip-card-left {
    perspective: 1000px;
  }
  .flip-card-inner-left {
    transition: transform 0.6s;
    /* transform-style: preserve-3d; */
  }
  .flip-card-left:hover .flip-card-inner-left {
    transform: rotateY(-5deg);
  }
  
  .color__hi {
    color: #03e9f4;
  }
  
  /* TooltipEffect */
  
  /* con-tooltip - movimiento de icono */
  .con-tooltip {
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    cursor: default;
  }
  
  .con-tooltip:hover .tooltip {
    visibility: visible;
    transform: translateY(-10px);
    opacity: 1;
    transition: 0.3s linear;
    animation: odsoky 1s ease-in-out infinite alternate;
  }
  
  .top:hover {
    transform: translateY(-6px);
  }
  .bottom:hover {
    transform: translateY(6px);
  }
  
  @keyframes odsoky {
    0% {
      transform: translateY(6px);
    }
  
    100% {
      transform: translateY(1px);
    }
  }
  
  /*tooltip */
  
  .tooltip {
    visibility: hidden;
    z-index: 1;
    opacity: 0.4;
    width: 200%;
    text-align: center;
    background: #333;
    color: #ffffff;
    position: absolute;
    top: -140%;
    left: -25%;
    border-radius: 9px;
    transform: translateY(9px);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
  }
  
  /* tooltip  after*/
  .tooltip::after {
    content: " ";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12.5px 0 12.5px;
    border-color: #333 transparent transparent transparent;
    position: absolute;
    left: 20%;
  }
  
  /*bottom*/
  
  .bottom .tooltip {
    top: 115%;
    left: -20%;
  }
  
  .bottom .tooltip::after {
    top: -17%;
    left: 40%;
    transform: rotate(180deg);
  }
  
  @media only screen and (max-width: 1279px) {
    .tooltip {
      visibility: hidden;
      z-index: 1;
      opacity: 0.4;
      width: 100px;
      font-size: 10px;
      text-align: center;
      background: #333;
      color: #ffffff;
      position: absolute;
      top: -140%;
      left: -25%;
      border-radius: 9px;
      transform: translateY(9px);
      transition: all 0.3s ease-in-out;
      box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
      font-size: 13px;
    }
    .top .tooltip {
      top: -210%;
      left: -90%;
    }
  
    .bottom .tooltip {
      top: 120%;
      left: -120%;
    }
  
  }
  
  @media only screen and (max-width: 639px) {
    .tooltip {
      visibility: hidden;
      z-index: 1;
      opacity: 0.4;
      width: 100px;
      text-align: center;
      background: #333;
      color: #ffffff;
      position: absolute;
      top: -140%;
      left: -25%;
      border-radius: 9px;
      transform: translateY(9px);
      transition: all 0.3s ease-in-out;
      box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
      font-size: 13px;
    }
    .top .tooltip {
      top: -210%;
      left: -90%;
    }
  
    .bottom .tooltip {
      top: -210%;
      left: -90%;
    }
  
    .bottom .tooltip::after {
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 12.5px 0 12.5px;
      border-color: #333 transparent transparent transparent;
      position: absolute;
      left: 20%;
      transform: rotate(0deg);
      top: 100%;
    }
  }
  