.progress-icon-wrapper {
  position: relative;
  display: inline-block; /* O ajusta según el diseño de tu página */
}

.progress-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
