.contact__button_links_github a {
    position: relative;
    display: inline-block;
    padding: 20px 20px;
    border-radius: 50%;
    color: #080809;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 10px;
    letter-spacing: 4px;
  }
  
  .contact__button_links_github a:hover {
    background: #080809;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #080809, 0 0 25px #080809, 0 0 50px #080809,
      0 0 100px #03e9f4;
  }
  .contact__button_links_gmail a {
    position: relative;
    display: inline-block;
    padding: 20px 20px;
    border-radius: 50%;
    color: #DD0C0C;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 10px;
    letter-spacing: 4px;
  }
  
  .contact__button_links_gmail a:hover {
    background: #DD0C0C;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DD0C0C, 0 0 25px #DD0C0C, 0 0 50px #DD0C0C,
      0 0 100px #03e9f4;
  }
  .contact__button_links_linkedin a {
    position: relative;
    display: inline-block;
    padding: 20px 20px;
    border-radius: 50%;
    color: #0C83DD;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 10px;
    letter-spacing: 4px;
  }
  
  .contact__button_links_linkedin a:hover {
    background: #0C83DD;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #0C83DD, 0 0 25px #0C83DD, 0 0 50px #0C83DD,
      0 0 100px #03e9f4;
  }
  
  .row {
    display: flex;
    justify-content: center; /* Centra los elementos en el eje principal */
  }